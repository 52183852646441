<template>
  <div class="content-game-loading">
    <div class="m-auto text-center">
      <Spinner label="Loading..." class="m-auto spinner-main"></Spinner>
      <br />
      กรุณารอสักครู่
    </div>
  </div>
</template>

<script>
export default {
  async mounted() {
    try {
      let redirect_Page = () => {
        let tID = setTimeout(async () => {
          if (!this.$store.state.templateReady)
            this.$store.dispatch("setReady");
          if (this.$route.name == "landing page")
            if (this.$route.query.redirect) {
              const params = new URLSearchParams(window.location.search);

              let redirectPath = params.get("redirect");
              if (window.location.pathname == "/") {
                return this.$router.replace(redirectPath);
              }
            } else {
              if (window.location.pathname == "/") {
                return this.$router.replace("/profile");
              } else {
                return this.$router.replace(window.location.pathname);
              }
            }

          window.clearTimeout(tID);
        }, 1000);
      };

      let isInLine = await this.$liff.isInClient();
      let isMobileDevice = window.matchMedia("(any-pointer: coarse)").matches;
      let getOs = await this.$liff.getOS();
      if (
        !isInLine &&
        isMobileDevice &&
        (getOs == "web" || getOs == "ios" || getOs == "android")
      ) {
        setTimeout(() => {
          if (this.$route.path) {
            window.location.href = `line://app/${this.$liffId}?redirect=${window.location.pathname}${window.location.search}`;
          } else {
            window.location.href = `line://app/${this.$liffId}/${window.location.pathname}${window.location.search}`;
          }
        }, 1000);
      }
      redirect_Page();
    } catch (error) {
      console.error(error);
      // alert(error, "error here2");
    }
  }
};
</script>

<style>
.content-game-loading {
  height: 100vh;
  /* position: absolute; */
  top: 0;
  width: 100%;
  background: white;
  z-index: 1;
  display: flex;
}
</style>
