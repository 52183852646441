<template>
  <div>
    <v-progress-circular color="primary" indeterminate></v-progress-circular>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
