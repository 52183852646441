import axios from "axios";

import VueCookies from "vue-cookies";
import { useFavicon } from "@vueuse/core";
const icon = useFavicon();
axios.interceptors.request.use(function (config) {
  if (VueCookies.isKey("token")) {
    config.headers.Authorization = `Bearer ${VueCookies.get("token")}`;
  }

  config.headers.common["API-KEY"] = process.env.VUE_APP_API_KEY;
  config.headers.common["Access-Control-Allow-Origin"] = "*";
  return config;
});
// Add API response
axios.interceptors.response.use(function (response) {
  return Promise.resolve(response.data);
});

let baseURL = "";
let location = window.location.hostname;
let brand = location.split("-")[0];
var subdomain = location.split(".");
subdomain.shift();
subdomain = subdomain.join(".");
var campaignUrl = ``;
var mode = "prod";
if (brand == "hourglass") subdomain = "dosetech.co";
if (
  brand == "linecrm" ||
  brand == "d" ||
  process.env.NODE_ENV == "development"
) {
  baseURL = "https://api-hourglass-mobile-dev.dosetech.co";
  campaignUrl = `https://api-hourglass-campaign-dev.dosetech.co/api/RegisterForm/UploadImage`;
  mode = "dev";
} else {
  if (brand == "clarins") {
    baseURL = "https://clubbeaute-mobile-api.dosetech.co";
    campaignUrl = `https://api-customerform-clarins-prod.dosetech.co/api/RegisterForm/UploadImage`;
  } else {
    campaignUrl = `https://api-${brand}-campaign-prod.${subdomain}/api/RegisterForm/UploadImage`;
    baseURL = `${window.location.protocol}//api-${brand}-mobile-${mode}.${subdomain}`;
  }
}

const instance = axios.create({
  withCredentials: true,
  baseURL: baseURL,
  responseType: "json",
  timeout: 20000
});

instance.interceptors.request.use(function (config) {
  config.headers.Authorization = `Bearer ${VueCookies.get("token")}`;
  // }
  //
  config.headers.common["API-KEY"] = process.env.VUE_APP_API_KEY;
  config.headers.common["Access-Control-Allow-Origin"] = "*";
  return config;
});
// Add API response
instance.interceptors.response.use(
  function (response) {
    return Promise.resolve(response.data);
  },
  function (error) {
    return Promise.reject(error.response);
  }
);
async function fetchConfig() {
  try {
    if (brand == "5t8z5pcj") {
      brand = "hourglass";
    }

    document.title = brand.charAt(0).toUpperCase() + brand.slice(1);
    if (mode == "dev") {
      icon.value = "/images/dev.ico";
      document.title = "D-CRM";
    } else icon.value = `/images/${brand}.ico`;
    const response = await instance.get("/api/v1/setting/getline");

    return response.detail; // Assuming the response is JSON data
  } catch (error) {
    console.error("Error fetching config:", error);
    return null;
  }
}
export { instance, axios, fetchConfig, campaignUrl };
