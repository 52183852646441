import store from "../store";

export default async function guestAuth({ next, to }) {
  const res = await store.dispatch("checkHasUser");

  if (res.result == 0) return next();
  else if (!res) return next();

  return next({ path: "/profile" });
}
