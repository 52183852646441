<template>
  <div>
    <v-img
      :src="src"
      @click="$emit('click')"
      cover
      :aspect-ratio="ratio"
      class="h-100"
      lazy-src="../../assets/images/default-image.png"
    >
      <template v-slot:error>
        <v-img
          class="mx-auto"
          height="300"
          max-width="500"
          src="../../assets/images/default-image.png"
        ></v-img>
      </template>
    </v-img>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      required: true
    },
    ratio: {
      required: false,
      type: String,
      default: "1"
    }
  }
};
</script>

<style></style>
