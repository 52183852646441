import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { registerComponents } from "@/components";
import vuetify from "./plugins/vuetify";
import "./style.css";
import { instance, axios, fetchConfig, campaignUrl } from "./services/axios";
import "./plugins/fontawesome";
import mitt from "mitt";
import vueDebounce from "vue-debounce";
import VueNumerals from "vue-numerals";
import VueCookies from "vue-cookies";
import liff from "@line/liff";
import swal from "sweetalert";
import DatetimePicker from "vuetify-datetime-picker";
import moment from "moment";
import VueClipboard from "vue-clipboard2";
import QrcodeVue from "qrcode.vue";
import globalMixin from "./globalMixin";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

const emitter = mitt();

const app = createApp({
  extends: App
  // mixins: [globalMixin]
});
app.component("VueDatePicker", VueDatePicker);
app.component("QrcodeVue", QrcodeVue);
app

  .use(VueClipboard)
  .use(vueDebounce)
  .use({
    // this is the required "install" method for Vue plugins
    install(Vue) {
      // Vue.swal = swal;
      app.config.globalProperties.$swal = swal;
    }
  })
  .use(VueCookies)
  .use(DatetimePicker)
  .use(VueNumerals);

VueClipboard.config.autoSetContainer = true; // add this line
app.config.globalProperties.$apiKey = "ChAtA7Ds40p";
app.config.globalProperties.$formatDate = "DD MMM YYYY";
app.config.globalProperties.$formatDateTime = "DD MMM YYYY HH:mm";
if (window.Cypress) {
  app.config.globalProperties.$liff = window.Cypress.liffMock;
} else {
  app.config.globalProperties.$liff = liff;
}
app.config.globalProperties.$liffUrl = process.env.VUE_APP_LIFF_URL;

app.config.globalProperties.handleImageSrc = require("@/assets/images/default-image.png");
app.config.globalProperties.$Endpoint =
  process.env.VUE_APP_DOMAIN || window.location.origin;
app.config.globalProperties.$nissinTransaction =
  process.env.VUE_APP_TRANSACTION_API ||
  "https://api-middleware-nissin.d-dots.com/api/transactioncode/redeem";
app.config.globalProperties.$store = store;
app.config.globalProperties.$router = router;
app.config.globalProperties.$axios = instance;
app.config.globalProperties.axios = axios;
app.config.globalProperties.emitter = emitter;
app.config.globalProperties.$EndPointImage = campaignUrl;
app.config.globalProperties.moment = date => moment(date);
registerComponents(app);
async function initializeApp() {
  const config = await fetchConfig();
  if (config) {
    let location = window.location.hostname;
    let brand = location.split("-")[0];
    let liffId = "";
    if (brand == "dcrm") liffId = "2002586609-E4VLV96m";
    else if (brand == "5t8z5pcj") liffId = "1656234832-gyrQbQVW";
    else liffId = config.memberLiffUrl.split("/")[3];
    app.config.globalProperties.UpcomingReward =
      config.display_upcomming_redeem == 1 ? true : false;
    app.config.globalProperties.$liffId = liffId;
    app.config.globalProperties.$BRAND =
      brand.charAt(0).toUpperCase() + brand.slice(1);
    instance(`/api/v1/Setting/theme`).then(async data => {
      store.commit("setTheme", data);
      app.config.globalProperties.yearFormat = data.result.year_format;
      if (data.result.year_format == 1) {
        app.config.globalProperties.$moment = date =>
          moment(date).add("543", "year");
      } else app.config.globalProperties.$moment = date => moment(date);
      liff.init({ liffId: liffId }).then(() => {
        app.use(router).use(store).use(vuetify).mount("#app");
      });
      // .catch(error => console.log(error));
    });
  } else {
    // Handle error or provide fallback configuration
  }
}

// Call initializeApp to start the process
initializeApp();
