<template>
  <div>
    <v-radio-group
      v-model="val"
      :value="value"
      @update:modelValue="$emit('update:value', $event)"
      :inline="inline"
      hide-details
      :error="isValidate"
    >
      <template v-for="items of options" :key="items['valueField']">
        <v-radio :label="items[textField]" :value="items[valueField]"></v-radio>
      </template>
    </v-radio-group>

    <template v-if="eachValid != null">
      <template v-if="eachValid.$error && v.$error">
        <div>
          <small v-if="v.required && v.required.$invalid" class="text-error">
            {{ validateText }}
          </small>
          <small v-else-if="!v.required" class="text-error">
            {{ validateText }}
          </small>
        </div>
      </template>
    </template>
    <template v-else>
      <template v-if="v.$error">
        <div class="mt-2">
          <small
            v-if="(v.required && v.required.$invalid) || !v.required"
            class="text-error"
          >
            {{ validateText }}
          </small>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      required: true,
      default: []
    },
    valueField: {
      required: false,
      default: "value"
    },
    textField: {
      required: false,
      default: "text"
    },
    value: {
      required: true
    },
    v: {
      required: false,
      default: () => {
        return {};
      }
    },
    validateText: {
      required: false
    },
    isValidate: {
      required: false
    },
    eachValid: {
      required: false,
      default: null
    },
    inline: { required: false, type: Boolean, default: true }
  },

  computed: {
    val() {
      return this.value;
    }
  }
};
</script>

<style></style>
