import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Footer from "./Footer";
import InputSelect from "./input/InputSelect.vue";
import InputText from "./input/InputText.vue";
import ModalMessage from "./alert-modal/ModalMessage";
import Image from "./other/Image";
import Button from "./other/Button";
import Spinner from "./other/Spinner";
import ModalConfirm from "./alert-modal/ModalConfirm";
import DatePicker from "./input/DatePicker.vue";
import InputRadio from "./input/InputRadio.vue";
import InputCheckbox from "./input/InputCheckbox.vue";

export const registerComponents = app => {
  app.component("ModalMessage", ModalMessage);
  app.component("c-img", Image);
  app.component("c-btn", Button);
  app.component("c-img-lazy", Image);
  app.component("ModalConfirm", ModalConfirm);
  app.component("Footer", Footer);
  app.component("font-awesome-icon", FontAwesomeIcon);
  app.component("datetime", DatePicker);
  app.component("Spinner", Spinner);
  app.component("InputText", InputText);
  app.component("InputRadio", InputRadio);
  app.component("InputSelect", InputSelect);
  app.component("InputCheckbox", InputCheckbox);
};
